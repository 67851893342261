import { render, staticRenderFns } from "./EditLegalWriter.vue?vue&type=template&id=04fb6046&scoped=true"
import script from "./EditLegalWriter.vue?vue&type=script&lang=js"
export * from "./EditLegalWriter.vue?vue&type=script&lang=js"
import style0 from "./EditLegalWriter.vue?vue&type=style&index=0&id=04fb6046&prod&scoped=true&lang=css"
import style1 from "./EditLegalWriter.vue?vue&type=style&index=1&id=04fb6046&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04fb6046",
  null
  
)

export default component.exports